.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-actions {
    margin-bottom: 1rem;
}

.card-button {
    text-transform: none;
    font-weight: 600;
}