.search {
  min-width: 24rem;
  position: relative;
  left: 4rem;
}

.hero {
  background-image: url("../assets/hero_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-grid {
  width: 100%;
}

.hero-heading {
  color: #fff;
  font-size: 1.5rem;
  width: 80%;
  position: relative;
  left: 1rem;
}

.hero-highlight {
  color: #212121;
  font-weight: 700;
  background: #ffb825;
  border-radius: 5px;
  padding: 0.2rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.search-mobile {
  display: block !important;
}

.search-desktop {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .hero {
    justify-content: flex-end;
    height: 20rem;
    padding-right: 8rem;
  }
  .hero-heading {
    position: static;
    font-size: 3rem;
    width: 50%;
  }
  .search-mobile {
    display: none !important;
  }
  .search-desktop {
    display: block !important;
  }
}
